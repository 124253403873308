<template>
  <div class="row">
    <div role="button" class="col-md-4 col-xs-12" @click="() => $emit('input', 'DELIVERY')">
      <div class="card text-center" :class="{'border-primary': value == 'DELIVERY'}">
        <div class="card-header pt-3 pb-3">
          <a-icon type="arrow-right" />
          <div class="text-uppercase text-dark font-weight-bold">{{ $t('delivery') }}</div>
        </div>
        <div class="card-body pt-3 pb-3">
          <div class="text-center text-gray-5">{{ $t('msg.shipment.create.single.delivery') }}</div>
        </div>
      </div>
    </div>
    <div role="button" class="col-md-4 col-xs-12" @click="() => $emit('input', 'RETURN')">
      <div class="card text-center" :class="{'border-primary': value == 'RETURN'}">
        <div class="card-header pt-3 pb-3">
          <a-icon type="rollback" />
          <div class="text-uppercase text-dark font-weight-bold">{{ $t('return') }}</div>
        </div>
        <div class="card-body pt-3 pb-3">
          <div class="text-center text-gray-5">{{ $t('msg.shipment.create.single.return') }}</div>
        </div>
      </div>
    </div>
    <div role="button" class="col-md-4 col-xs-12" @click="() => $emit('input', 'EXCHANGE')">
      <div class="card text-center" :class="{'border-primary': value == 'EXCHANGE'}">
        <div class="card-header pt-3 pb-3">
          <a-icon type="retweet" />
          <div class="text-uppercase text-dark font-weight-bold">{{ $t('exchange') }}</div>
        </div>
        <div class="card-body pt-3 pb-3">
          <div class="text-center text-gray-5">{{ $t('msg.shipment.create.single.exchange') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Type',
  props: ['value'],
  data() {
    return {
      selected: this.value,
    }
  },
}
</script>
