<template>
  <div class="row">
    <div role="button" class="col-md-6" @click="() => $emit('input', 'single')">
      <div class="card" :class="{'border-primary': value == 'single'}">
        <div class="card-body pt-4 pb-4">
          <div class="text-center">
            <a-icon type="form" />
            <h4 class="font-weight-bold">{{ $t('msg.shipment.create.single.title') }}</h4>
            <div class="text-gray-5">{{ $t('msg.shipment.create.single.subtitle') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div role="button" class="col-md-6" @click="() => $emit('input', 'multi')">
      <div class="card" :class="{'border-primary': value == 'multi'}">
        <div class="card-body pt-4 pb-4">
          <div class="text-center">
            <a-icon type="table" />
            <h4 class="font-weight-bold">{{ $t('msg.shipment.create.multi.title') }}</h4>
            <div class="text-gray-5">{{ $t('msg.shipment.create.multi.subtitle') }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Mode',
  props: ['value'],
}
</script>
