<template>
  <div>
    <h6 class="mb-4 text-uppercase">
      <strong>{{ $t('msg.shipment.create.single.section1') }}</strong>
    </h6>
    <j-type v-model="form.type" class="mb-4" />
    <h6 class="mb-4 text-uppercase">
      <strong>{{ $t('msg.shipment.create.single.section2') }}</strong>
    </h6>
    <div class="card p-3">
      <div class="row card-body">
        <!-- General -->
        <div class="col-xl-5 col-lg-12 mb-2">
          <h5>{{ $t('general') }}</h5>
        </div>
        <div class="col-xl-7 col-lg-12">
          <a-form-model
            ref="general"
            :model="form"
            :rules="rules">
            <j-account
              v-model="owner"
              v-if="$auth.granted(acl.shipments.monitor)" />
            <j-import
              prop="ref"
              v-model="form.ref"
              :format="f.upper"
              @import="onImport" />
            <j-enum-select
              prop="size"
              v-model="form.size" />
            <j-input
              prop="value"
              v-model="form.value"
              icon="dollar" />
            <j-input
              prop="notes"
              v-model="form.notes"
              icon="form" />
          </a-form-model>
        </div>
        <a-divider />
        <!-- Address -->
        <div class="col-xl-5 col-lg-12 mb-2">
          <h5>{{ $t('address') }}</h5>
        </div>
        <div class="col-xl-7 col-lg-12">
          <a-form-model
            ref="point"
            :model="form.point.address"
            :rules="rules">
            <j-input
              prop="street"
              v-model="form.point.address.street"
              icon="environment" />
            <j-input
              prop="apartment"
              v-model="form.point.address.apartment"
              icon="bell" />
            <j-zip
              prop="zip"
              v-model="form.point.address.zip"
              icon="global"
              :help="$t('msg.zone.autocomplete')"
              @change="onZoneChange"/>
            <j-input
              prop="city"
              v-model="form.point.address.city"
              icon="environment" />
            <j-input
              prop="state"
              v-model="form.point.address.state"
              icon="environment" />
          </a-form-model>
        </div>
        <a-divider />
        <!-- Contact -->
        <div class="col-xl-5 col-lg-12 mb-2">
          <h5>{{ $t('contact') }}</h5>
        </div>
        <div class="col-xl-7 col-lg-12">
          <a-form-model
              ref="contact"
              :model="form.point.contact"
              :rules="rules">
            <j-input
              prop="name"
              v-model="form.point.contact.name"
              icon="user" />
            <j-input
              prop="phone"
              v-model="form.point.contact.phone"
              :format="f.phone"
              icon="phone" />
            <j-input
              prop="email"
              v-model="form.point.contact.email"
              :format="f.lower"
              icon="mail" />
            <j-input
              prop="identification"
              v-model="form.point.contact.identification"
              icon="idcard" />
          </a-form-model>
          <div class="font-size-18 mt-4">
            <a-button
              @click="onSubmit"
              type="primary"
              size="large">
              {{ $t('msg.shipment.single.request') }}
            </a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import v from '@/utils/validators'
import f from '@/utils/formatters'
import acl from '@/auth/acl'
import lms from '@/api/lms'
import JType from './type'
import JInput from '@/views/shared/forms/input'
import JAccount from '@/views/shared/forms/account'
import JZip from '@/views/shared/forms/zip'
import JEnumSelect from '@/views/shared/forms/enum-select'
import JImport from './import'

export default {
  components: {
    JType,
    JInput,
    JImport,
    JAccount,
    JZip,
    JEnumSelect,
  },
  data: function () {
    return {
      f,
      acl,
      owner: undefined,
      form: {
        type: 'DELIVERY',
        ref: undefined,
        notes: undefined,
        value: undefined,
        store: undefined,
        size: undefined,
        point: {
          contact: {},
          address: {
            street: undefined,
            apartment: undefined,
            zip: undefined,
            city: undefined,
            state: undefined,
            country: 'AR',
          },
        },
      },
      rules: {
        ref: [v.required, v.ref],
        street: [v.required],
        zip: [v.required],
        city: [v.required],
        state: [v.required],
        phone: [v.required, v.phone],
        value: [v.money],
        size: [v.required],
        email: [v.email],
        name: [v.required],
      },
    }
  },
  methods: {
    onImport(shipment) {
      if (shipment) {
        this.form.value = shipment.value
        this.form.notes = shipment.notes
        this.form.point = shipment.destination
        this.$message.success(this.$t('msg.shipment.imported'))
      }
    },
    onZoneChange(zone) {
      this.form.point.address.city = zone.meta.city
      this.form.point.address.state = zone.meta.state
    },
    onSubmit() {
      Promise.all([
        new Promise((resolve, reject) => this.$refs.general.validate(resolve)),
        new Promise((resolve, reject) => this.$refs.point.validate(resolve)),
        new Promise((resolve, reject) => this.$refs.contact.validate(resolve)),
      ]).then(results => {
        if (results.every(Boolean)) {
          const point = {
            DELIVERY: 'destination',
            RETURN: 'origin',
            EXCHANGE: 'destination',
          }
          const body = {
            type: this.form.type,
            ref: this.form.ref,
            value: this.form.value,
            size: this.form.size,
            notes: this.form.notes,
            account: this.owner,
          }
          body[point[this.form.type]] = this.form.point
          lms.shipment.create(body)
            .then(response => this.$emit('result', response))
        }
      })
    },
  },
}
</script>
