<template>
  <a-form-model-item prop="account" :colon="false" has-feedback>
    <span slot="help" class="text-dark"></span>
    <span slot="label" class="text-dark">{{$t('account')}}</span>
    <a-select
      v-model="selected"
      v-bind="$attrs"
      size="large"
      :show-search="true"
      :show-arrow="false"
      :filter-option="false"
      :not-found-content="null"
      @search="onSearch"
      @change="onChange">
      <a-select-option
        v-for="account in accounts"
        :key="account.key">
        {{ account.value }}
      </a-select-option>
    </a-select>
  </a-form-model-item>
</template>

<script>
import lms from '@/api/lms'
import scheduler from '@/utils/scheduler'

const suggester = scheduler(p => lms.suggestion.search(p.query, p.type))

export default {
  props: ['value'],
  data() {
    return {
      selected: undefined,
      accounts: [],
    }
  },
  watch: {
    value: {
      deep: false,
      immediate: true,
      handler: 'init',
    },
  },
  methods: {
    init() {
      this.selected = this.value
    },
    onChange() {
      this.$emit('input', this.selected)
      this.$emit('change', this.selected)
    },
    onSearch(query) {
      suggester.schedule({ query, type: 'ACCOUNT' }).then(r => (this.accounts = r.data))
    },
  },
}
</script>
