<template>
  <a-form-model-item :prop="prop" :colon="false">
    <span slot="help" class="text-dark"></span>
    <span slot="label" class="text-dark">{{ label || $t(prop) }}</span>
    <a-input
      v-model="text"
      v-bind="$attrs"
      size="large">
      <a-icon
        slot="prefix"
        type="number"
        style="color: rgba(0,0,0,.25)" />
      <a-tooltip
        slot="suffix"
        :title="$t('import')">
        <a-button
          icon="plus"
          shape="circle"
          size="small"
          :disabled="!value"
          :loading="loading"
          @click="fetch" />
      </a-tooltip>
    </a-input>
  </a-form-model-item>
</template>

<script>
import lms from '@/api/lms'
export default {
  name: 'JImport',
  props: [
    'value',
    'prop',
    'label',
    'format',
  ],
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    text: {
      get() {
        return this.value
      },
      set(value) {
        const formatted = this.format
          ? this.format.format(value)
          : value
        this.$emit('input', formatted)
        this.$emit('change', formatted)
      },
    },
  },
  methods: {
    fetch() {
      this.loading = true
      lms.platform.fetchShipment(this.account, this.value)
        .then(r => this.$emit('import', r.data))
        .finally(() => (this.loading = false))
    },
  },
}
</script>
