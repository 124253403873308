<template>
  <div class="main">
    <h6 class="mb-4 text-uppercase">
      <strong>
        {{ $t('msg.shipment.mode') }}
      </strong>
    </h6>
    <j-mode
      v-model="mode"
      class="mb-4" />
    <transition
      name="fadein"
      mode="out-in">
      <j-single
        v-if="mode === 'single'"
        @result="onSingleResult" />
      <j-multi
        v-else
        @result="onMultiResult" />
    </transition>
  </div>
</template>

<script>
import JMode from './mode'
import JSingle from './single'
import JMulti from './multi'
export default {
  components: {
    JMode,
    JSingle,
    JMulti,
  },
  data: function () {
    return {
      mode: 'single',
    }
  },
  methods: {
    onSingleResult(result) {
      this.$router.push(`/shipments/success?type=create&code=${result.data.code}`)
    },
    onMultiResult() {
      this.$router.push('/shipments')
    },
  },
}
</script>
