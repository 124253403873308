<template>
  <div>

    <!-- Settings -->

    <h6 class="mb-4 text-uppercase">
      <strong>{{ $t('settings') }}</strong>
    </h6>
    <div class="card p-3">
      <div class="card-body row">
        <div class="col-xl-5 col-lg-12 mb-2">
        </div>
        <div class="col-xl-7 col-lg-12">
          <a-form-model>
            <!-- Type -->
            <j-field prop="format">
              <a-select
                v-model="type"
                size="large">
                <a-select-option
                  v-for="key in available"
                  :key="key" :value="key">
                  {{ $t(`enum.csv.${key}`) }}
                </a-select-option>
              </a-select>
            </j-field>
            <!-- Origin -->
            <j-store
              v-model="origin"
              :owner="account" />
          </a-form-model>
        </div>
      </div>
    </div>

    <!-- File Upload -->

    <h6 class="mb-4 text-uppercase">
      <strong>{{ $t('msg.shipment.create.multi.section1') }}</strong>
    </h6>
    <div class="card p-3">
      <div class="card-body">
        <a-upload-dragger
          name="file"
          :multiple="false"
          :customRequest="onUploadFile"
          :showUploadList="false">

          <!-- Macintosh -->

          <div v-if="type === 'MAC'">
            <p class="ant-upload-drag-icon">
              <a-avatar
                shape="square"
                :size="64"
                :src="`/resources/images/platforms/JPK.png`" />
            </p>
            <p class="ant-upload-text">
              {{ $t('msg.shipment.create.multi.upload.title') }}
            </p>
            <p class="ant-upload-hint">
              {{ $t('msg.shipment.create.multi.upload.subtitle1') }}
            </p>
            <div class="mt-3">
              <a href="/resources/csv/mac.csv"
                v-on:click.stop="() => {}"
                target="_blank">
                <a-button type="link" size="small" icon="apple">
                  {{ $t('msg.shipment.create.multi.upload.action2') }}
                </a-button>
              </a>
            </div>
          </div>

          <!-- Windows -->

          <div v-else-if="type === 'WIN'">
            <p class="ant-upload-drag-icon">
              <a-avatar
                shape="square"
                :size="64"
                :src="`/resources/images/platforms/JPK.png`" />
            </p>
            <p class="ant-upload-text">
              {{ $t('msg.shipment.create.multi.upload.title') }}
            </p>
            <p class="ant-upload-hint">
              {{ $t('msg.shipment.create.multi.upload.subtitle1') }}
            </p>
            <div class="mt-3">
              <a href="/resources/csv/windows.csv"
                v-on:click.stop="() => {}"
                target="_blank">
                <a-button type="link" size="small" icon="windows">
                  {{ $t('msg.shipment.create.multi.upload.action1') }}
                </a-button>
              </a>
            </div>
          </div>

          <!-- Other -->

          <div v-else>
            <p class="ant-upload-drag-icon">
              <a-avatar
                shape="square"
                :size="64"
                :src="`/resources/images/platforms/${type}.png`" />
            </p>
            <p class="ant-upload-text">
              {{ $t('msg.shipment.create.multi.upload.title') }}
            </p>
            <p class="ant-upload-hint">
              {{ $t('msg.shipment.create.multi.upload.subtitle2') }}
            </p>
          </div>

          <p
            v-for="error in errors" :key="error.name"
            class="text-danger mt-4">
            {{ $t(`msg.validator.${error.code}`, { name: error.name }) }}
          </p>
        </a-upload-dragger>
      </div>
    </div>

    <!-- Preview -->

    <h6 class="mb-4 text-uppercase">
      <strong>{{ $t('preview') }}</strong>
    </h6>
    <div class="card">
      <div class="card-body py-0 px-4">
        <a-list
          :data-source="preview"
          :pagination="false">
          <a-list-item slot="renderItem" slot-scope="shipment">
            <j-shipment :shipment="shipment">
              <template #direction>
                <a-tag :color="operation[shipment.type].color">
                  <a-icon :type="operation[shipment.type].icon" />
                </a-tag>
              </template>
              <template #status>
                <div>
                  <a-avatar
                    class="float-left mr-3"
                    icon="eye" />
                  <div class="text-dark font-size-16 pt-1">
                    {{ $t('preview') }}
                  </div>
                </div>
              </template>
            </j-shipment>
          </a-list-item>
        </a-list>
      </div>
    </div>

    <!-- Button -->

    <div class="font-size-18 mt-4">
      <a-button
        type="primary"
        icon="rocket"
        size="large"
        :disabled="!shipments.length"
        @click="onSubmit">
        {{ $t('msg.shipment.multi.request') }}
      </a-button>
    </div>
  </div>
</template>

<script>
import lms from '@/api/lms'
import { operation } from '@/utils/styles'
import JStore from '@/views/shared/forms/store'
import JShipment from '@/views/shared/items/shipment'
import JField from '@/views/shared/forms/field'
export default {
  components: {
    JStore,
    JShipment,
    JField,
  },
  data() {
    return {
      operation,
      origin: undefined,
      errors: [],
      shipments: [],
      columns: [
        {
          title: this.$t('ref'),
          dataIndex: 'ref',
          scopedSlots: { customRender: 'reference' },
          width: 140,
        },
      ],
    }
  },
  computed: {
    type: {
      get () {
        return this.$store.state.settings.importType
      },
      set (importType) {
        this.$store.commit('SET_IMPORT_TYPE', { importType })
      },
    },
    preview() {
      return this.shipments.map(s => ({ ...s, origin: this.origin }))
    },
    available() {
      return ['WIN', 'MAC', 'SHF', 'MAG']
    },
  },
  methods: {
    onUploadFile(request) {
      lms.bff.parseCSV(this.type, request.file)
        .then(response => {
          this.shipments = response.data
          this.errors = []
          request.onSuccess()
        })
        .catch(e => {
          if (e.response?.data?.code === 'INVALID_REQUEST') {
            this.errors = e.response.data.errors
          }
        })
    },
    onSubmit() {
      const body = this.preview
      lms.shipment.create(body)
        .then(response => this.$emit('result', response))
    },
  },
}
</script>
